<template>
  <div class="login site__centered">

    <div v-if="!chosenRole" class="form">
      <div class="form__wrap login__form">
        <div>
          <h2 class="form__title">Login</h2>
          <p class="form__subtitle">For access to your {{ $config['name'] }} account, please choose the role</p>
          <button
            class="btn btn--color-dark form__button"
            @click="chosenRole = availableRoleName"
          >
            <span>Login as <span class="capitalize">{{ availableRoleName }}</span></span>
          </button>
          <button
            class="btn btn--color-dark form__button"
            @click="chosenRole='staff'"
          >
            <span>Login as Staff</span>
          </button>
        </div>
      </div>
    </div>

      <form
        v-else
        class="form"
        @submit.prevent="login"
      >
            <div class="form__wrap login__form">
                <h2 class="form__title">Login</h2>
                <p class="form__subtitle">For access to your {{ $config['name'] }} account, please login</p>

              <p v-if="callbackErrorMessage"
                 class="form__error"
              >
                {{ callbackErrorMessage }}
              </p>

              <p v-if="!availableRegularLogin(chosenRole, authSettings) && errorMessage"
                 class="form__error"
              >
                {{ errorMessage }}
              </p>

              <template v-if="availableRegularLogin(chosenRole, authSettings)">
                  <label class="form__row ">
                      <span class="form__row-label">Email *</span>
                      <input
                              v-model="formData.userEmail"
                              type="text"
                              placeholder="email"
                              :class="{'is-invalid': $v.formData.userEmail.$dirty &&
                              ( !$v.formData.userEmail.required || !$v.formData.userEmail.email) }"
                      />
                      <p
                        v-if="$v.formData.userEmail.$dirty && !$v.formData.userEmail.required"
                        class="error"
                      >
                        The Email field is required
                      </p>
                      <p
                        v-else-if="$v.formData.userEmail.$dirty && !$v.formData.userEmail.email"
                        class="error"
                      >
                        Enter a valid email
                      </p>
                  </label>

                  <label class="form__row">
                      <span class="form__row-label">Password *</span>
                      <input
                              @keyup.enter="login()"
                              v-model="formData.password"
                              type="password"
                              placeholder="password"
                              :class="{'is-invalid': $v.formData.password.$dirty &&
                              (!$v.formData.password.required || !$v.formData.password.minLength) }"
                      />
                      <p
                        v-if="$v.formData.password.$dirty && !$v.formData.password.required"
                        class="error"
                      >
                        The Password field is required
                      </p>
                      <p
                        v-else-if="$v.formData.password.$dirty && !$v.formData.password.minLength"
                        class="error"
                      >
                        Minimum Password length {{$v.formData.password.$params.minLength.min}} characters
                      </p>
                  </label>

                  <p v-if="errorMessage"
                     class="form__error"
                  >
                      {{errorMessage}}
                  </p>

                  <div class="form__row form__one-row">
                      <div class="center">
                          <label class="center nice-checkbox">
                            <input type="checkbox" id="remember" name="remember" />
                            <span class="text-secondary">Remember me</span>
                          </label>
                      </div>
                      <router-link
                        :to="{name: 'forgot-password'}"
                        class="form__forgot"
                      >Forgot password?</router-link>
                  </div>

                  <button class="btn btn--color-dark form__button" :class="{ 'btn--loading': isLoading }"><span v-if="!isLoading">Login Account</span></button>
                </template>
                <span
                  v-if="availableRegularLogin(chosenRole, authSettings) && availableRegularRegistration(chosenRole, authSettings)"
                  class="center"
                >Or</span>
                <router-link
                    v-if="availableRegularRegistration(chosenRole, authSettings)"
                    :to="{name: 'registration', params: { return: returnPageRouteName, routeParams: returnPageRouteParams, role: chosenRole }}"
                    class="btn btn--color-outline-bright form__button"
                >Register Account</router-link>

                <template v-if="providersList.length && availableOauthLogin(chosenRole, authSettings)">
                  <span
                    v-if="availableRegularLogin(chosenRole, authSettings)"
                    class="center"
                  >Or Login with</span>
                  <button
                    v-for="provider in providersList"
                    :key="provider.id"
                    class="btn form__button"
                    :style="{
                      background: provider.btn_color,
                      color: '#fff',
                    }"
                    @click.prevent="handleOAuthLogin(provider)"
                  >
                    <span>{{ provider.btn_name}}</span>
                  </button>
                </template>

              <span
                v-if="chosenRole"
                class="center"
              >Or</span>
              <button
                class="btn btn--color-outline-dark form__button"
                @click="choseAnotherRole"
              >
                <span>Choose Another Role</span>
              </button>

            </div>
        </form>

        <!-- <welcome-block class="mobile-hidden"/> -->
    </div>
</template>

<script>
  import axios from '@axios'
  import useJwt from '@/auth/jwt/useJwt'
  import {required, minLength, email} from 'vuelidate/lib/validators'

  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  import store from '@/store'
  import { mapGetters } from 'vuex'
  import { openWindow } from '@/helpers/openWindow'
  import { prepareProgramRegistrationInfo } from '@core/mixins/prepareProgramRegistrationInfo'

  export default {
    mixins: [prepareProgramRegistrationInfo],
    data() {
      return {
        status: '',
        returnPageRouteName: null,
        returnPageRouteParams: null,
        formData: {
          userEmail: null,
          password: null,
        },
        isLoading: false,
        errorMessage: '',
        callbackErrorMessage: '',
        providersList: [],
        chosenRole: null,
      }
    },
    validations: {
      formData: {
        userEmail: {required, email},
        password: {
          required,
          minLength: minLength(6)
        }
      }
    },
    computed: {
      ...mapGetters({
        familyId: 'family/getFamilyId',
        authSettings: 'authSettings/getAuthSettings',
      }),
      availableRoleName() {
        return this.$config.enable_student_signup ? 'student' : 'parent'
      },
    },
    async created() {
      store.commit('SET_LOADING_STATUS', false)

      await this.fetchOAuthProviders()

      this.$emit('loading', false)
      if (this.$route.params.return){
        this.returnPageRouteName = this.$route.params.return
      }
      if (this.$route.params.routeParams){
        this.returnPageRouteParams = this.$route.params.routeParams
      }
      if (this.$route.query.email){
        this.formData.userEmail = this.$route.query.email
      }
      if (this.$route.query.family){
        store.commit('family/SET_FAMILY_ID', this.$route.query.family)
      }
    },
    mounted () {
      window.addEventListener('message', this.onMessage, false)

      if (this.$route.params.role) {
        this.chosenRole = this.$route.params.role
      }
    },

    beforeDestroy () {
      window.removeEventListener('message', this.onMessage)
    },
    methods: {
      choseAnotherRole() {
        this.callbackErrorMessage = null
        this.errorMessage = null
        this.chosenRole = null
      },
      async login() {
        if (this.$v.$invalid){
          await this.$v.$touch();
          document.querySelector('.is-invalid').focus()
          return
        }

        this.isLoading = true

        useJwt.login({
          email: this.formData.userEmail,
          password: this.formData.password,
        })
          .then(response => {
            this.setTokenAndUserData(response)
          })
          .catch(err => {
            if (err.response) {
              if ( err.response.status === 402 ) this.errorMessage = 'Incorrect email address and / or password'
              if ( err.response.status === 422 ) {
                const serverMessage = Object.values(err.response.data.errors)
                this.errorMessage = serverMessage.flat(Infinity).join(' ')
              }
            }
          })
          .finally(() => {
            this.isLoading = false
          })

      },
      async setTokenAndUserData(response) {
        const {userData} = response.data
            
        if (!userData.is_approved) {
          this.errorMessage = 'Your account is under review, please check back later.'
          return
        }

        useJwt.setToken(response.data.accessToken)
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$ability.update(userData.ability)

        this.$store.commit('CHANGE_USER_STATUS', userData.id)
        this.$store.commit('SET_USER_DATA', userData)

        if (userData.role === 'admin') {
          await this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          return
        }

        if (this.returnPageRouteName) {
          if (userData.role === 'parent' && this.returnPageRouteName === 'application-create') {
            await this.$router.replace({ name: 'application-select-child', params: this.returnPageRouteParams})
            return
          }

          if ((userData.role === 'staff' || userData.role === 'child') && this.returnPageRouteName === 'application-create') {
            let query = {}
            if (this.returnPageRouteParams.id) {
              const application = await this.fetchUserApplication(this.returnPageRouteParams.id)

              if (application.id) {
                query = {app_id: application.id, user_id: application.user_id}
              }
            }
            await this.$router.replace({
              name: this.returnPageRouteName,
              params: this.returnPageRouteParams,
              query: query
            })
            return
          }

          await this.$router.replace({ name: this.returnPageRouteName, params: this.returnPageRouteParams})
        } else {
          await this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        }

        if (this.familyId) {
          const queryParams = {
            family_id: this.familyId,
            user_id: userData.id,
          }
          store.dispatch('family/addNewMember', queryParams)
        }
      },
      async fetchUserApplication(programId) {
        try {
          this.$emit('loading', true)
          const response = await axios.get(`user-applications/${programId}`)
          return response.data.data
        } finally {
          this.$emit('loading', false)
        }
      },
      async fetchOAuthProviders() {
        try {
          this.$emit('loading', true)
          const response = await axios.get('providers-list')
          this.providersList = response.data.data
        } finally {
          this.$emit('loading', false)
        }
      },
      handleOAuthLogin(provider) {
        this.callbackErrorMessage = null
        openWindow(provider.auth_url, provider.btn_name)
      },
      onMessage (e) {
        if (e.data.errorMessage) {
          this.callbackErrorMessage = e.data.errorMessage
        }
        if (!e.data.accessToken) {
          return
        }
        this.setTokenAndUserData(e)
      },
    },
  }
</script>

<style scoped>
.error {
  position: absolute;
  color: red;
  font-size: 14px;
}
</style>
